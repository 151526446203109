var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pageWrapper"},[(!_vm.employee)?_c('Loader',{attrs:{"color":'#444'}}):_c('div',[_c('PrevPage',{attrs:{"title":"Back to employees"}}),_c('SaveDiscardMenu',{attrs:{"discardChanges":_vm.discardChanges,"saveChanges":_vm.saveChanges,"isChanged":_vm.isChanged,"title":_vm.employee.username,"displayDelete":true,"delete":{
        message: 'Are you sure you want to delete ' + _vm.employee.username + '?',
        execute: _vm.deleteEmployee
      }}}),_c('MessageBox',{attrs:{"data":{
        transition: 0.2,
        height: '4vw',
        close: { auto: true, time: 5 }
      }}}),_c('div',{staticClass:"divider"},[_c('div',{staticStyle:{"display":"flex"}},[_c('InfoWrapperBox',[_c('div',[_c('H4',[_vm._v("Firstname")]),_c('StandardInput',{attrs:{"type":"text","placeholder":"Employee firstname"},model:{value:(_vm.employee.firstname),callback:function ($$v) {_vm.$set(_vm.employee, "firstname", $$v)},expression:"employee.firstname"}})],1),_c('div',[_c('H4',[_vm._v("Lastname")]),_c('StandardInput',{attrs:{"type":"text","placeholder":"Employee lastname"},model:{value:(_vm.employee.lastname),callback:function ($$v) {_vm.$set(_vm.employee, "lastname", $$v)},expression:"employee.lastname"}})],1)])],1)]),_c('div',{staticClass:"divider"},[_c('InfoWrapperBox',[(_vm.employee.roles.length > 0)?_c('fragment',[_c('H4',[_vm._v("Selected roles")]),_c('div',{staticClass:"roleWrapper"},_vm._l((_vm.employee.roles),function(role){return _c('div',{key:role.id,staticClass:"roleBox",on:{"click":function($event){return _vm.removeRole(role)}}},[_vm._v(" "+_vm._s(role.title)+" ")])}),0)],1):_vm._e(),_c('H4',[_vm._v("Add more roles")]),_c('div',{staticClass:"roleWrapper"},_vm._l((_vm.filteredRoles),function(role){return _c('div',{key:role.id,staticClass:"roleBox",on:{"click":function($event){return _vm.addRole(role)}}},[_vm._v(" "+_vm._s(role.title)+" ")])}),0),(!_vm.filteredRoles.length > 0)?_c('fragment',[_c('div',[_vm._v(" "+_vm._s(!_vm.filteredRoles.length > 0 && !_vm.employee.roles.length > 0 ? 'No roles created.' : 'No more roles.')+" Navigate to"),_c('router-link',{attrs:{"to":{ name: 'Roles' }},nativeOn:{"click":function($event){return _vm.toggleSidebar()}}},[_vm._v(" Roles ")]),_vm._v(" add a new role. ")],1)]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="pageWrapper">
    <Loader v-if="!employee" :color="'#444'" />
    <div v-else>
      <PrevPage title="Back to employees" />
      <SaveDiscardMenu
        :discardChanges="discardChanges"
        :saveChanges="saveChanges"
        :isChanged="isChanged"
        :title="employee.username"
        :displayDelete="true"
        :delete="{
          message: 'Are you sure you want to delete ' + employee.username + '?',
          execute: deleteEmployee
        }"
      />
      <MessageBox
        :data="{
          transition: 0.2,
          height: '4vw',
          close: { auto: true, time: 5 }
        }"
      />
      <div class="divider">
        <div style="display: flex">
          <InfoWrapperBox>
            <div>
              <H4>Firstname</H4>
              <StandardInput
                type="text"
                placeholder="Employee firstname"
                v-model="employee.firstname"
              />
            </div>
            <div>
              <H4>Lastname</H4>
              <StandardInput
                type="text"
                placeholder="Employee lastname"
                v-model="employee.lastname"
              />
            </div>
          </InfoWrapperBox>
        </div>
      </div>
      <div class="divider">
        <InfoWrapperBox>
          <fragment v-if="employee.roles.length > 0">
            <H4>Selected roles</H4>
            <div class="roleWrapper">
              <div
                class="roleBox"
                @click="removeRole(role)"
                v-for="role in employee.roles"
                :key="role.id"
              >
                {{ role.title }}
              </div>
            </div>
          </fragment>

          <H4>Add more roles</H4>
          <div class="roleWrapper">
            <div
              class="roleBox"
              @click="addRole(role)"
              v-for="role in filteredRoles"
              :key="role.id"
            >
              {{ role.title }}
            </div>
          </div>
          <fragment v-if="!filteredRoles.length > 0">
            <div>
              {{
                !filteredRoles.length > 0 && !employee.roles.length > 0
                  ? 'No roles created.'
                  : 'No more roles.'
              }}
              Navigate to<router-link
                @click.native="toggleSidebar()"
                :to="{ name: 'Roles' }"
              >
                Roles
              </router-link>
              add a new role.
            </div>
          </fragment>
        </InfoWrapperBox>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import Loader from '@/components/Loader'
import SaveDiscardMenu from '@/components/SaveDiscardMenu'
import PrevPage from '@/components/PrevPage'
import MessageBox from '@/components/MessageBox'
import InfoWrapperBox from '@/layout/InfoWrapperBox'
import StandardInput from '@/layout/StandardInput.vue'
import H4 from '@/layout/typography/H4.vue'

export default {
  components: {
    Loader,
    SaveDiscardMenu,
    PrevPage,
    MessageBox,
    InfoWrapperBox,
    StandardInput,
    H4
  },
  data() {
    return {
      employee: undefined,
      baseEmployee: undefined,
      roles: []
    }
  },
  created() {
    this.getEmployee()
    this.getRoles()
  },
  computed: {
    isChanged() {
      if (JSON.stringify(this.employee) == JSON.stringify(this.baseEmployee)) {
        return false
      }
      return true
    },
    filteredRoles() {
      return this.roles.filter(role => {
        if (!this.employee.roles.find(el => el.role_id === role.id)) {
          return role
        }
      })
    }
  },
  methods: {
    addRole(role) {
      this.employee.roles.push({ role_id: role.id, title: role.title })
    },
    removeRole(role) {
      let index = this.employee.roles.findIndex(
        el => el.role_id === role.role_id
      )
      this.employee.roles.splice(index, 1)
    },
    deleteEmployee() {
      axios
        .post('employees/deleteEmployee', {
          employeeId: this.employee.id
        })
        .then(res => {
          this.$router.back()
        })
        .catch(err => {
          console.log(err.response.data.msg)
          this.$store.commit('SET_MESSAGEBOX', {
            message: err.response.data.msg,
            type: 'error'
          })
        })
    },
    getRoles() {
      axios.get('roles/getRoles', {}).then(response => {
        this.roles = response.data
      })
    },
    saveChanges() {
      axios
        .post('employees/updateEmployee', this.employee)
        .then(res => {
          this.$store.commit('SET_MESSAGEBOX', {
            message: 'Employee has been updated',
            type: 'success'
          })
          this.baseEmployee = JSON.parse(JSON.stringify(this.employee))
          this.getEmployee()
        })
        .catch(err => {
          this.$store.commit('SET_MESSAGEBOX', {
            message: err.response.data.msg,
            type: 'error'
          })
        })
    },
    discardChanges() {
      this.employee = JSON.parse(JSON.stringify(this.baseEmployee))
    },
    getEmployee() {
      axios
        .post('employees/getEmployee', {
          employeeId: this.$route.params.employeeId
        })
        .then(res => {
          this.employee = JSON.parse(JSON.stringify(res.data))
          this.baseEmployee = JSON.parse(JSON.stringify(res.data))
        })
        .catch(err => {
          this.$router.replace({ name: 'Home' })
        })
    }
  }
}
</script>

<style scoped lang="scss">
.divider {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 60%;
  grid-gap: 20px;
  margin: 10px 0;
}

.roleWrapper {
  display: flex;
  flex-wrap: wrap;
}

.roleBox {
  margin: 10px 10px 10px 0;
  background: white;
  padding: 7px 10px;
  border-radius: 5px;
  width: 20%;
  -webkit-box-shadow: 0px 0px 2px 1px rgba(219, 219, 219, 1);
  -moz-box-shadow: 0px 0px 2px 1px rgba(219, 219, 219, 1);
  box-shadow: 0px 0px 2px 1px rgba(219, 219, 219, 1);
  cursor: pointer;
}

.roleBox:hover {
  background: whitesmoke;
}

@media screen and (max-width: 700px) {
  .pageWrapper {
    margin: auto;
    width: 100%;
  }
  .divider {
    position: relative;
    width: 100%;
    display: inline;
  }
}
</style>
